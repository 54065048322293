import { defineComponent } from 'vue';
import { hasMetricsFeature, hasR4cFactoringFeature, hasAnamneseFeature, hasAnyR4cTabletFeature } from '@/../../base';
import { isProd } from '@rose/common-ui';
import { authStore } from '@/state/authStore';
import { editSettingsRootStore } from '@/state/settings/editSettingsRootStore';
export default defineComponent({
  data: function data() {
    return {
      currentTab: '',
      isProd: isProd
    };
  },
  computed: {
    isLoading: function isLoading() {
      return editSettingsRootStore.state.isLoading;
    },
    hasMetrics: function hasMetrics() {
      return hasMetricsFeature(authStore.getters.profile);
    },
    hasFactoring: function hasFactoring() {
      return hasR4cFactoringFeature(authStore.getters.profile);
    },
    hasAnamnese: function hasAnamnese() {
      return hasAnamneseFeature(authStore.getters.profile);
    },
    showTabletsTab: function showTabletsTab() {
      return hasAnyR4cTabletFeature(authStore.getters.profile);
    }
  },
  beforeMount: function beforeMount() {
    void editSettingsRootStore.dispatch.init();
  }
});